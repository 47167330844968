.contactSec{
    background: #F4F7FF;
    padding: 100px 20px;
    /* padding: 300px 20px 100px; */
}
.contactSec form{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0 20px;
}
.contactSec form .flexbox{
    display: flex;
    flex-direction: column;
    max-width: 550px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    flex: 1;
}
.contactSec form .flexbox .inputBox{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.contactSec .flexbox .inputBox label{
    color: #030F38;
    font-family: 'Ubuntu';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: capitalize;
    /* margin: 15px 0; */
    margin-bottom: 15px;
}
.contactSec .flexbox .inputBox input,
.contactSec .flexbox .inputBox textarea{
    font-family: 'Poppins';
    border-radius: 8px;
    border: 1px solid #04F6BB;
    background: #FFF;
    padding: 15px;
    line-height: 24px;
}
.contactSec .flexbox .inputBox input:placeholder,
.contactSec .flexbox .inputBox textarea:placeholder{
    color: #798196;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}
.contactSec form .flexbox .messageBox,
.contactSec form .flexbox .messageBox > textarea{
    flex: 1;
}

.errorMsg{
    color: #58151c;
    padding: 15px 5px 0;
    margin: 0;
}

input[type="submit"]{
    padding: 16px 40px;
    border-radius: 8px;
    background: #0093FE;
    border: 0;
    color: #FFF;
    font-family: 'Ubuntu';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media screen and (max-width: 700px){
    .contactSec{
        padding: 100px 20px;
    }
    .contactSec form{
        flex-direction: column;
    }
    .contactSec form .flexbox{
        max-width: none;
        align-items: center;
    }
}
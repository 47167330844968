.swiper{
    overflow: hidden !important;
    max-width: 1100px;
    padding: 0 60px 40px !important;
}
.slide{
    border-radius: 25px;
    border: 2px solid #04F6BB;
    /* box-shadow: 0px 14px 29px 0px rgba(10, 10, 62, 0.25); */
    overflow: hidden;
    aspect-ratio: 7/9;
    max-width: 350px;
}
.slide > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
}
.slide > .info{
    position: absolute;
    bottom: 25px;
    min-width: 200px;
    border-radius: 0px 8px 8px 0px;
    background: rgba(0, 0, 0, 0.50);
    border-left: 5px solid #04F6BB;

    display: flex;
    flex-direction: column;
    padding: 20px;
}
.slide > .info h3{
    color: #04F6BB;
    font-family: 'Titillium Web';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: capitalize;
    margin: 0;
}
.slide > .info h6{
    color: #ACB8DF;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.slide > .info h5{
    color: #F4F7FF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-transform: capitalize;
    margin: 0;
}

@media screen and (max-width: 600px){
    .swiper{
        margin: 0 70px !important;
    }
}
@media screen and (max-width: 500px){
    .swiper{
        margin: 0 20px !important;
    }
}
@media screen and (max-width: 460px){
    .swiper{
        margin: 0 !important;
    }
}
.services{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 30px 0;
    padding-right: 20px;
    gap: 20px;
    max-width: 1600px;
    width: 100%;
    box-sizing: border-box;

    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.serviceBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-width: 300px;
    max-width: 400px;
    padding: 30px 30px 30px 60px;
    margin-left: 60px;

    border-radius: 16px;
    border: 1px solid #3ED5DD;
    background: linear-gradient(137deg, #171717 0%, #323335 0.01%, rgba(90, 90, 90, 0.00) 49.48%, #171717 100%);
    box-shadow: 0px 8px 27px 8px rgba(2, 155, 173, 0.05);
    color: #fff;

    flex: 1;
    position: relative;
}
.serviceBox > .imgBox{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    width: 60px;
    height: 60px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 50%;
    border: 1px solid #3ED5DD;
    background: linear-gradient(137deg, #484848 0%, rgba(72, 72, 72, 0.90) 0.01%, rgba(90, 90, 90, 0.00) 49.48%, rgba(72, 72, 72, 0.90) 100%), #323335;
}
.serviceBox > .imgBox > img{
    width: 48px;
    height: 48px;
}

.serviceBox >h4{
    color:#FFF;
    font-size: 24px;
    font-family: "Titillium Web";
    font-weight: 600;
    line-height: 32px;
    margin: 0;
}
.serviceBox >p{
    color: #FFF;
    font-size: 16px;
    font-family: "Open Sans";
    font-weight: 600;
    line-height: 24px;
}

@media (max-width: 700px){
    .services{
        padding-right: 50px;
    }
    .serviceBox{
        margin-left: 75px;
        min-width: 200px;
    }
}

@media (max-width: 400px){
    .services{
        padding-right: 5px;
    }
    .serviceBox{
        margin-left: 55px;
        min-width: 200px;
    }
}
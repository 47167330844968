.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 100px;
}

.contactButton{
    background-color: #04F6BB;
    padding: 16px 40px;
    color: #111B34;
    font-size: 16px;
    border-radius: 8px;
    text-decoration: none;
}

.mobileMenuIcon{
    display: none;
}

.languagePicker > button > img,
.mobileLanguagePicker > button > img{
    margin-right: 5px;
}

.languagePicker a,
.mobileLanguagePicker a{
    color: var(--bs-dropdown-link-color) !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.mobileLanguagePicker > button{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 900px){
    .header{
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        box-sizing: border-box;
        z-index: 99;
        background-color: #101A53;

        padding: 30px;
        overflow: hidden;
    }
    .logo{
        width: 100px;
    }

    .mobileHide{
        display: none !important;
    }
    .mobileMenuIcon{
        display: block;
        width: 40px;
    }
    .languagePicker{
        display: none;
    }
}

.chooseSec{
    /* padding: 50px 0 250px; */
    padding: 50px 0;
    position: relative;
}
.container{
    max-width: 600px;
    padding: 0 20px;
    position: relative;
    left: 150px;
}
.chooseSec > img{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

@media (max-width: 1200px) {
    .container{
        left: 100px;
    }
}

@media (max-width: 950px) {
    .chooseSec > img{
        display: none;
    }
    .container{
        left: 50%;
        transform: translateX(-50%);
        padding: 0 20px;
    }
    .chooseMain{
        text-align: center;
    }
}

@media (max-width: 700px) {
    .chooseSec{
        padding: 50px 0;
    }
}
/* @media (max-width: 450px) {
    .chooseSec{
        padding-bottom: 350px;
    }
} */